import React from 'react'

const Submit = () => {
  return (
    <button className='btn btn-dark' type='submit'>
      Create QR
    </button>
  )
}

export default Submit
